body {
  color: $color-text;
  background-color: $color-background;
}

a {
  color: $color-highlight;

  &:visited {
    color: darken($color-highlight, 15%);
  }

  &:hover {
    color: $color-text;
  }
}

blockquote {
  color: $color-spacer;
  border-left: 4px solid $color-spacer-highlight;
}

pre,
code {
  border: 1px solid $color-spacer-highlight;
  background-color: $color-code-background;
}

table {
  color: lighten($color-text, 18%);
  border: 1px solid $color-spacer-highlight;

  tr {
    &:nth-child(even) {
      background-color: lighten($color-spacer-highlight, 6%);
    }
  }

  th {
    background-color: lighten($color-spacer-highlight, 3%);
    border: 1px solid darken($color-spacer-highlight, 4%);
    border-bottom-color: darken($color-spacer-highlight, 12%);
  }
  td {
    border: 1px solid $color-spacer-highlight;
  }
}

// Global Classes

.footer-col-wrapper {
  color: $color-spacer;
}

.highlight {
  color: $color-highlight;
}

.page-content {
  & > div.wrapper {
    background-color: $color-content-background;
  }
}

.post-meta {
  color: $color-spacer;
}

.product {
  &-panel {
    &-features,
    &-support {
      &--header {
        color: $color-highlight;
      }
    }
  }
}

.site-footer {
  border-top: 1px solid $color-spacer-highlight;

  svg.site-title {
    fill: $color-logo-header;
  }
}

.site-header {
  border-top: 5px solid $color-spacer-lowlight;
  border-bottom: 1px solid $color-spacer-highlight;

  background: $color-nav-bar-bg;

  svg.site-title {
    fill: $color-logo-header;
  }
}

.site-nav {
  a {
    &:visited {
      color: $color-highlight;
    }
  }
}

@include media-query-min($on-palm) {
  .site-nav {
    *::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $color-nav-scrollbar-shadow;
    }

    *::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px $color-nav-scrollbar-thumb;
      background-color: $color-nav-scrollbar-thumb-bg;
    }

    & ul > li.menu-type-dropdown {
      background: $color-nav-lowlight;
    }

    & > ul li {
      ul {
        background: $color-nav-panel-bg;

        .menu-item-title {
          color: $color-nav-header;
        }
      }

      &.menu-type-dropdown {
        &:hover {
          li.menu-type-dropdown {
            &:hover {
              background: $color-nav-highlight;

              & > .menu-item-title {
                color: $color-nav-item-select;
              }
            }
          }
        }
      }

      &.menu-type-panel {
        background: $color-nav-lowlight;

        &.menu-type-dropdown {
          &:not(:last-child) {
            border-right: 1px solid $color-nav-spacer;
          }

          &:hover {
            li.menu-type-dropdown {
              &:hover {
                background: $color-nav-highlight;

                & > .menu-item-title {
                  color: $color-nav-item-select;
                }
              }
            }
          }
        }

        &.menu-type-panel {
          & > ul {
            background: $color-nav-panel-bg;

            .menu-item-title {
              color: $color-nav-item-normal;
            }
          }
        }
      }
    }
  }
}

@include media-query($on-palm) {
  .site-nav {
    *::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px $color-nav-scrollbar-shadow;
    }

    *::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px $color-nav-scrollbar-thumb;
      background-color: $color-nav-scrollbar-thumb-bg;
    }

    .menu-trigger {
      svg {
        fill: $color-logo-header;
      }
    }

    & > ul {
      background: $color-nav-bar-bg_mobile;

      & > li > .menu-item-title {
        background: $color-nav-lowlight;
      }
    }
  }
}

.svg-icon {
  fill: $color-spacer;
}

.site-footer {
  a:hover {
    .svg-icon {
      fill: $color-highlight;
    }
  }
}
